<template>
  <video class="fixed" crossorigin="anonymous" playsinline="" preload="auto" muted="" loop="" autoplay="" tabindex="-1" width="100%" height="100%" src="https://video.wixstatic.com/video/df6cf8_c87009461fe04666b7039d17177e265d/1080p/mp4/file.mp4" style="width: 100%; height: 100%; object-fit: cover; object-position: center center; opacity: 1; z-index: -1;"></video>
  <div class="min-h-full">
    <nav class="">
      <div class="px-4 pt-4 mx-auto max-w-8xl sm:px-6 lg:px-20">
        <div class="flex flex-col justify-between h-28 sm:flex-row">
          <div class="flex">
            <div class="flex items-center flex-shrink-0 mx-auto mb-8 sm:mx-0 sm:mb-0">
              <img width="70" height="70" src="./assets/logo.png" alt="" />
            </div>
          </div>
          <div class="flex flex-col items-center justify-between sm:flex-row">
            <div
              v-if="isConnected && !initLoading && swap"
              class="flex mx-10 mb-6 text-xs font-semibold text-white sm:text-sm sm:flex-row sm:mb-0"
            >
              <div class="p-1.5 px-2 rounded-tl-sm rounded-bl-sm bg-king-btn">
                Connected
              </div>
              <div class="p-1.5 px-2 rounded-tr-sm rounded-br-sm bg-gray-700">
                {{ start_and_end(web3.address) }}
              </div>
            </div>
            <div class="flex flex-row mx-0">
              <a class="mx-3 text-lg font-semibold text-king-header" target="_blank" href="https://twitter.com/KINGSHIBABSC">Twitter</a>
              <a class="mx-3 mb-5 mr-0 text-lg font-semibold text-king-header sm:mr-14 sm:mb-0" target="_blank" href="https://t.me/KINGOFSHIBA">Telegram</a>
            </div>
            <div class="flex flex-row mx-0 my-5 sm:my-0">
              <button
                v-if="!isConnected"
                type="button"
                class="inline-flex items-center px-4 py-2 text-base font-medium border border-transparent rounded-md shadow-sm text-king-txt bg-king-btn hover:bg-king-dark"
                @click="init()"
              >
                Connect Wallet
                <Spinner v-if="initLoading" />
              </button>
              <button
                v-if="isConnected && initLoading"
                type="button"
                class="inline-flex items-center px-4 py-2 text-base font-medium border border-transparent rounded-md shadow-sm text-king-txt bg-king-btn hover:bg-king-dark"
              >
                Connecting Wallet
                <Spinner />
              </button>
              <button
                v-else-if="isConnected"
                type="button"
                class="inline-flex items-center px-4 py-2 text-base font-medium border border-transparent rounded-md shadow-sm text-king-txt bg-king-btn hover:bg-king-dark"
                @click="disconnect()"
              >
                Disconnect Wallet
              </button>
              <a href="/staking" class="inline-flex items-center px-4 py-2 ml-5 text-base font-medium border border-transparent rounded-md shadow-sm text-king-txt bg-king-btn hover:bg-king-dark">Staking</a>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div class="py-6">
      <main>
        <div class="max-w-3xl mx-auto sm:px-6 lg:px-8">
          <div class="px-4 py-8 sm:px-0">
            <div v-if="isConnected && !swap && !initLoading" class="text-left max-w-full p-3 mx-auto mb-10 text-white bg-red-600 rounded-xl">
              There is an ongoing issue at the moment, the team is aware and will be resolving ASAP. <br>Please try again after 30min.
            </div>
            <div
              class="flex flex-col p-10 bg-opacity-5 rounded-xl bg-king-bg min-h-96 mt-44 sm:mt-0"
            >
              <p class="text-2xl font-bold text-center text-king-header">
                King Shiba Bridge
              </p>
              <network-header :network="smartContractType" />
              <div class="mt-4">
                <p class="text-sm text-white">
                  Enter the amount you want to swap
                </p>
                <div
                  class="flex flex-col items-center h-auto px-6 py-2 pb-4 mt-4 sm:flex-row sm:pb-2 rounded-xl bg-king-amount"
                >
                  <div class="flex flex-col w-full pt-2 sm:pt-0">
                    <input
                      v-model="swapAmount"
                      :disabled="!swap"
                      class="w-3/4 text-xl font-bold text-white bg-transparent border-none focus:outline-none focus:ring-2 focus:ring-offset focus:ring-gray-600"
                      type="text"
                    />
                    <p class="text-white text-opacity-50">
                      $KINGSHIB Balance:
                      <span v-if="swap">{{
                        userBalanceFormatted(swap.token)
                      }}</span
                      ><span v-else>0</span>
                    </p>
                  </div>
                  <hr class="w-full my-4 border-gray-500 sm:hidden" />
                  <div class="relative flex w-full sm:w-auto">
                    <p class="text-2xl font-bold text-white text-opacity-30">
                      $KINGSHIB
                    </p>
                    <div
                      class="hidden mx-4 border-r border-gray-500 sm:block"
                    ></div>
                    <button
                      :disabled="!swap"
                      class="ml-auto font-semibold text-white hover:text-gray-300"
                      @click="maxAmount"
                    >
                      Max
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="!swap" class="flex justify-center mt-8">
                <button
                  v-if="!isConnected"
                  type="button"
                  class="inline-flex items-center justify-center w-full px-4 py-2 text-base font-medium border border-transparent rounded-md shadow-sm text-king-txt bg-king-btn hover:bg-king-dark"
                  @click="init()"
                >
                  Connect Wallet
                </button>
                <button
                  v-if="isConnected && initLoading"
                  type="button"
                  class="inline-flex items-center justify-center w-full px-4 py-2 text-base font-medium border border-transparent rounded-md shadow-sm text-king-txt bg-king-btn hover:bg-king-dark"
                >
                  Connecting Wallet
                  <Spinner />
                </button>
              </div>
              <div v-show="isConnected && swap" class="flex flex-col py-5 mt-8">
                <p class="mb-4 text-sm text-white">
                  Or use the slider/percent box below to set the amount to swap
                </p>
                <div class="px-5">
                  <Slider
                    v-model="swapPercentage"
                    :tooltips="false"
                    class="king-slider"
                    ref="kingSlider"
                    @update="onChangeSlider"
                  />
                </div>
              </div>
              <div
                v-if="isConnected && swap"
                class="flex flex-col items-center justify-center mt-8 sm:flex-row"
              >
                <button
                  type="button"
                  class="inline-flex items-center justify-center w-full px-4 py-2 mb-4 text-lg font-semibold text-white border border-transparent rounded-lg shadow-sm sm:mr-8 sm:mb-0 bg-king-btn hover:bg-king-dark"
                  @click="sendSwap"
                  :disabled="isLoading"
                >
                  Initiate Swap
                </button>
                <button
                  @click="manualClaimModal = true"
                  type="button"
                  class="inline-flex items-center justify-center w-full px-4 py-2 text-lg font-semibold text-white border border-transparent rounded-lg shadow-sm bg-king-dark hover:bg-king-btn"
                >
                  <span v-if="hasUnclaimedSentFromTarget &&
                    swap.unclaimedSentFromTarget.amount != 0.0">Claim Tokens</span>
                  <span v-else>Manual Claim</span>
                </button>
              </div>
              <div
                v-if="isConnected && swap && hasUnclaimedInSourceAndNotInitiatedClaiming"
                class="flex flex-col items-center justify-center mt-4 sm:flex-row"
              >
                <button
                  @click="onRefundModal = true"
                  type="button"
                  :disabled="isLoading"
                  class="inline-flex items-center justify-center w-full px-4 py-2 text-lg font-semibold text-white border border-transparent rounded-lg shadow-sm bg-king-dark hover:bg-king-btn"
                >
                  Refund Tokens
                </button>
              </div>
              <a
                href="https://oklg.io/"
                target="_blank"
                class="mt-8 text-base font-semibold text-center cursor-pointer text-king-header"
              >
                Powered by OKLG
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
    <claim-token-modal
      v-if="manualClaimModal"
      @close-modal="manualClaimModal = false"
    />
    <sent-token-modal
      v-if="successfullySentModal"
      @close-modal="successfullySentModal"
    />
    <refund-modal
      v-if="onRefundModal"
      @close-modal="onRefundModal = false"
      @refund="onRefundTokens"
    />
    <notifications position="top right" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BigNumber from "bignumber.js";
import NetworkHeader from "./components/NetworkHeader.vue";
import ClaimTokenModal from "./components/ClaimTokenModal.vue";
import SentTokenModal from "./components/SentTokenModal.vue";
import RefundModal from "./components/RefundModal.vue";
import Spinner from "./components/Spinner.vue";
import Slider from "@vueform/slider";

export default {
  name: "App",
  components: {
    NetworkHeader,
    ClaimTokenModal,
    SentTokenModal,
    RefundModal,
    Spinner,
    Slider,
  },
  data() {
    return {
      isLoading: false,
      swapAmount: 0,
      manualClaimModal: false,
      successfullySentModal: false,
      onRefundModal: false,
      swap_id: "",
      unique_identifier: "",
      swap_amount: 0,
      swapPercentage: 0,
    };
  },
  computed: {
    ...mapGetters([
      "isConnected",
      "initLoading",
      "activeNetwork",
      "web3",
      "swap",
    ]),
    smartContractType() {
      if (this.isConnected && !this.initLoading) {
        if (this.activeNetwork.network_id == 1) {
          return "ERC20";
        } else if (this.activeNetwork.network_id == 56) {
          return "BEP20";
        }
      } else {
        return "ERC20";
      }
    },
    swapAmountFormatted() {
      if (!this.swapAmount) {
        return 0;
      } else {
        return this.swapAmount;
      }
    },
    hasUnclaimedSentFromSource() {
      if (this.swap) {
        const targetSwap = this.swap.unclaimedSentFromSource;
        return (
          targetSwap &&
          new BigNumber(targetSwap.amount).gt(0) &&
          !targetSwap.isComplete &&
          !targetSwap.isRefunded
        );
      } else {
        return false;
      }
    },
    hasUnclaimedSentFromTarget() {
      if (this.swap) {
        const targetSwap = this.swap.unclaimedSentFromTarget;
        return (
          targetSwap &&
          new BigNumber(targetSwap.amount).gt(0) &&
          !targetSwap.isComplete &&
          !targetSwap.isRefunded
        );
      } else {
        return false;
      }
    },
    hasUnclaimedInSourceAndNotInitiatedClaiming() {
      if (this.swap) {
        const sourceSwap = this.swap.unclaimedSentFromSource;
        const targetSwap = this.swap.unclaimedSentFromTarget;
        return (
          sourceSwap &&
          new BigNumber(sourceSwap.amount).gt(0) &&
          !sourceSwap.isComplete &&
          !sourceSwap.isRefunded &&
          (!targetSwap || sourceSwap.id !== (targetSwap || {}).id)
        );
      } else {
        return false;
      }
    },
  },
  watch: {
    swapPercentage(value) {
      const currentBalance = this.swap.token.userBalance / Math.pow(10, this.swap.token.decimals)
      if (currentBalance = 0) {
        this.swapAmount = 0
      } else {
        this.swapAmount = ((value * currentBalance) / 100).toFixed()
      }
    },
    swapAmount(value) {
      this.swapPercentage = Math.round(100*value/(this.swap.token.userBalance / Math.pow(10, this.swap.token.decimals)))
    }
  },
  mounted() {
    document.getElementsByClassName(
      "slider-touch-area"
    )[0].innerText = `${this.swapPercentage}%`;
  },
  methods: {
    ...mapActions([
      "init",
      "disconnect",
      "sendTokensToSwap",
      "asaasInstanceGasCost",
      "asaasGetLatestUserSwap",
      "refundTokens",
      "getAllSwapContracts",
    ]),
    userBalanceFormatted(tokenInfo) {
      return new BigNumber(tokenInfo.userBalance)
        .div(new BigNumber(10).pow(tokenInfo.decimals))
        .toFormat(2);
    },
    intToString(num) {
      if (num < 1000) {
        return num;
      }
      let si = [
        { v: 1e3, s: "K" },
        { v: 1e6, s: "M" },
        { v: 1e9, s: "B" },
        { v: 1e12, s: "T" },
        { v: 1e15, s: "P" },
        { v: 1e18, s: "E" },
      ];
      let i;
      for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].v) {
          break;
        }
      }
      return `${
        (num / si[i].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
        si[i].s
      }`;
    },
    start_and_end(str) {
      return str.substr(0, 6) + "..." + str.substr(str.length - 4, str.length);
    },
    async sendSwap() {
      try {
        this.isLoading = true;
        if (!this.swapAmount || this.swapAmount == "0.00") return;
        const amountToFormat = new BigNumber(this.swapAmount.replace(/,/g, ""));
        const correctSendTokenAmount = new BigNumber(amountToFormat)
          .times(new BigNumber(10).pow(this.swap.token.decimals))
          .toFixed();
        await this.asaasInstanceGasCost(this.swap.sourceContract);
        await this.sendTokensToSwap({
          amount: correctSendTokenAmount,
          sourceContract: this.swap.sourceContract,
          tokenContract: this.swap.token.address,
        });
        this.latestSwap = await this.asaasGetLatestUserSwap(
          this.swap.sourceContract
        );
        localStorage.kingshibaLatestSwapId = this.latestSwap.id;
        localStorage.kingshibaLatestSwapTimestamp = this.latestSwap.origTimestamp;
        localStorage.kingshibaLatestSwapNumTokens = new BigNumber(
          correctSendTokenAmount
        )
          .div(new BigNumber(10).pow(this.swap.token.decimals))
          .toFormat();
        this.successfullySentModal = true;
        this.$notify({
          type: "error",
          text: "Successfully initiated your swap!",
        });
      } catch (err) {
        this.$notify({ type: "error", text: err.message });
      } finally {
        this.isLoading = false;
      }
    },
    async onRefundTokens(refund) {
      try {
        if (refund) {
          this.isLoading = true;
          await this.refundTokens({
            instContract: this.swap.sourceContract,
            id: this.swap.unclaimedSentFromSource.id,
            timestamp: this.swap.unclaimedSentFromSource.origTimestamp,
            amount: this.swap.unclaimedSentFromSource.amount,
          });
          this.$notify({
            type: "error",
            text: "Your tokens were successfully refunded!",
          });
          await this.getAllSwapContracts();
        } else {
          this.onRefundModal = false;
        }
      } catch (err) {
        this.$notify({ type: "error", text: err.message });
      } finally {
        this.isLoading = false;
      }
    },
    onChangeSlider(value) {
      document.getElementsByClassName(
        "slider-touch-area"
      )[0].innerText = `${value}%`;
    },
    maxAmount() {
      this.swapAmount = this.userBalanceFormatted(this.swap.token).replace(/,/g,'')
      this.swapPercentage = 100
    }
  },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
<style>
body {
  background: #151519;
}
.king-slider {
  --slider-bg: #d1d5db;
  --slider-connect-bg: #dd5200;
  --slider-connect-bg-disabled: #9ca3af;
  --slider-height: 6px;
  --slider-vertical-height: 300px;
  --slider-radius: 9999px;

  --slider-handle-bg: #c4c4c4;
  --slider-handle-border: 0;
  --slider-handle-width: 40px;
  --slider-handle-height: 20px;
  --slider-handle-radius: 5px;
  --slider-handle-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.32);
  --slider-handle-shadow-active: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.42);
  --slider-handle-ring-width: 3px;
  --slider-handle-ring-color: transparent;
}
.slider-touch-area {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #dd5200;
  font-size: 12px;
}
</style>
