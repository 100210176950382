<template>
    <div>
        <div v-if="network == 'ERC20'" class="flex items-center justify-between w-full h-full">
          <svg class="w-24 h-24 sm:h-20 sm:w-20" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_47:956)">
            <path opacity="0.65" d="M65.1759 40.3541C69.2357 22.5854 58.1224 4.88982 40.3536 0.830025C22.5849 -3.22977 4.88935 7.88353 0.829551 25.6523C-3.23025 43.421 7.88306 61.1166 25.6518 65.1764C43.4206 69.2361 61.1161 58.1228 65.1759 40.3541Z" fill="url(#paint0_radial_47:956)"/>
            <path d="M33.1106 51.1128C43.1128 51.1128 51.2212 43.0044 51.2212 33.0022C51.2212 23 43.1128 14.8916 33.1106 14.8916C23.1084 14.8916 15 23 15 33.0022C15 43.0044 23.1084 51.1128 33.1106 51.1128Z" fill="url(#paint1_linear_47:956)"/>
            <path d="M33.1106 51.1128C43.1128 51.1128 51.2212 43.0044 51.2212 33.0022C51.2212 23 43.1128 14.8916 33.1106 14.8916C23.1084 14.8916 15 23 15 33.0022C15 43.0044 23.1084 51.1128 33.1106 51.1128Z" stroke="#8A92B2" stroke-width="4" stroke-miterlimit="10"/>
            <path d="M33.4302 20.894L33.2686 21.4463V37.4558L33.4302 37.6174L40.8648 33.2267L33.4302 20.894Z" fill="#EDEEEF"/>
            <path d="M33.4301 20.894L26 33.2267L33.4301 37.6174V29.8461V20.894Z" fill="white"/>
            <path d="M33.43 39.0271L33.3402 39.1348V44.841L33.43 45.1059L40.8691 34.6364L33.43 39.0271Z" fill="#EDEEEF"/>
            <path d="M33.4301 45.1059V39.0271L26 34.6364L33.4301 45.1059Z" fill="white"/>
            <path d="M40.8647 33.2267L33.4301 37.6174V29.8461L40.8647 33.2267Z" fill="#D5D8DB"/>
            <path d="M33.4301 29.8461V37.6174L26 33.2267L33.4301 29.8461Z" fill="#EDEEEF"/>
            </g>
            <defs>
            <radialGradient id="paint0_radial_47:956" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33.0034 33.0028) scale(41.0025)">
            <stop stop-color="#B3B6C4"/>
            <stop offset="0.99" stop-color="#8A95BD" stop-opacity="0"/>
            </radialGradient>
            <linearGradient id="paint1_linear_47:956" x1="1761.95" y1="1952.36" x2="3812.07" y2="3711.99" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stop-color="#8A92B2"/>
            <stop offset="1" stop-color="#C1C2C7"/>
            </linearGradient>
            <clipPath id="clip0_47:956">
            <rect width="66" height="66" fill="white"/>
            </clipPath>
            </defs>
          </svg>

          <svg class="w-full mx-4 sm:h-20 sm:w-full h-28" viewBox="0 0 676 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 4.5H671.5" stroke="#303030" stroke-width="8" stroke-linecap="round"/>
          </svg>

          <svg class="w-24 h-24 sm:h-20 sm:w-20" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_47:971)">
            <path opacity="0.65" d="M64.2625 39.4567C68.0987 21.9209 56.993 4.59546 39.4573 0.759216C21.9215 -3.07702 4.59608 8.02863 0.759842 25.5644C-3.0764 43.1002 8.02927 60.4256 25.565 64.2618C43.1008 68.098 60.4262 56.9924 64.2625 39.4567Z" fill="url(#paint0_radial_47:971)"/>
            <path d="M31.6355 50.3384C41.4862 50.3384 49.4717 42.3529 49.4717 32.5022C49.4717 22.6516 41.4862 14.666 31.6355 14.666C21.7849 14.666 13.7993 22.6516 13.7993 32.5022C13.7993 42.3529 21.7849 50.3384 31.6355 50.3384Z" fill="url(#paint1_linear_47:971)"/>
            <path d="M31.8362 50.3384C41.6869 50.3384 49.6724 42.3529 49.6724 32.5022C49.6724 22.6516 41.6869 14.666 31.8362 14.666C21.9855 14.666 14 22.6516 14 32.5022C14 42.3529 21.9855 50.3384 31.8362 50.3384Z" stroke="#E9C229" stroke-width="4" stroke-miterlimit="10"/>
            <path d="M27.406 30.57L32.106 25.8656L36.8104 30.57L39.5429 27.8332L32.106 20.3962L24.6691 27.8332L27.406 30.57ZM20 32.4978L22.7369 29.7653L25.4738 32.4978L22.7325 35.2347L20 32.4978ZM27.406 34.43L32.106 39.1344L36.8104 34.43L39.5473 37.1624L32.106 44.6038L24.6691 37.1669L27.4104 34.4344L27.406 34.43ZM38.7426 32.5066L41.4795 29.7697L44.2164 32.5066L41.4795 35.2391L38.7426 32.5066Z" fill="white"/>
            <path d="M34.8783 32.4979L32.1016 29.7212L30.05 31.7727L29.8157 32.0115L29.3293 32.4979L32.106 35.2746L34.8827 32.4979" fill="white"/>
            </g>
            <defs>
            <radialGradient id="paint0_radial_47:971" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.5229 32.4944) scale(40.3813)">
            <stop stop-color="#FFF500"/>
            <stop offset="0.99" stop-color="#D278D8" stop-opacity="0"/>
            </radialGradient>
            <linearGradient id="paint1_linear_47:971" x1="1734.28" y1="1922.78" x2="3753.34" y2="3655.75" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E9C229"/>
            <stop offset="0.99" stop-color="#EA93DD"/>
            </linearGradient>
            <clipPath id="clip0_47:971">
            <rect width="65" height="65" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>
        <div class="flex items-center justify-between" v-else>
          <svg class="w-24 h-24 sm:h-20 sm:w-20" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_47:971)">
            <path opacity="0.65" d="M64.2625 39.4567C68.0987 21.9209 56.993 4.59546 39.4573 0.759216C21.9215 -3.07702 4.59608 8.02863 0.759842 25.5644C-3.0764 43.1002 8.02927 60.4256 25.565 64.2618C43.1008 68.098 60.4262 56.9924 64.2625 39.4567Z" fill="url(#paint0_radial_47:971)"/>
            <path d="M31.6355 50.3384C41.4862 50.3384 49.4717 42.3529 49.4717 32.5022C49.4717 22.6516 41.4862 14.666 31.6355 14.666C21.7849 14.666 13.7993 22.6516 13.7993 32.5022C13.7993 42.3529 21.7849 50.3384 31.6355 50.3384Z" fill="url(#paint1_linear_47:971)"/>
            <path d="M31.8362 50.3384C41.6869 50.3384 49.6724 42.3529 49.6724 32.5022C49.6724 22.6516 41.6869 14.666 31.8362 14.666C21.9855 14.666 14 22.6516 14 32.5022C14 42.3529 21.9855 50.3384 31.8362 50.3384Z" stroke="#E9C229" stroke-width="4" stroke-miterlimit="10"/>
            <path d="M27.406 30.57L32.106 25.8656L36.8104 30.57L39.5429 27.8332L32.106 20.3962L24.6691 27.8332L27.406 30.57ZM20 32.4978L22.7369 29.7653L25.4738 32.4978L22.7325 35.2347L20 32.4978ZM27.406 34.43L32.106 39.1344L36.8104 34.43L39.5473 37.1624L32.106 44.6038L24.6691 37.1669L27.4104 34.4344L27.406 34.43ZM38.7426 32.5066L41.4795 29.7697L44.2164 32.5066L41.4795 35.2391L38.7426 32.5066Z" fill="white"/>
            <path d="M34.8783 32.4979L32.1016 29.7212L30.05 31.7727L29.8157 32.0115L29.3293 32.4979L32.106 35.2746L34.8827 32.4979" fill="white"/>
            </g>
            <defs>
            <radialGradient id="paint0_radial_47:971" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.5229 32.4944) scale(40.3813)">
            <stop stop-color="#FFF500"/>
            <stop offset="0.99" stop-color="#D278D8" stop-opacity="0"/>
            </radialGradient>
            <linearGradient id="paint1_linear_47:971" x1="1734.28" y1="1922.78" x2="3753.34" y2="3655.75" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E9C229"/>
            <stop offset="0.99" stop-color="#EA93DD"/>
            </linearGradient>
            <clipPath id="clip0_47:971">
            <rect width="65" height="65" fill="white"/>
            </clipPath>
            </defs>
          </svg>

          <svg class="mx-2" width="676" height="9" viewBox="0 0 676 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 4.5H671.5" stroke="#303030" stroke-width="8" stroke-linecap="round"/>
          </svg>

          <svg class="w-24 h-24 sm:h-20 sm:w-20" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_47:956)">
            <path opacity="0.65" d="M65.1759 40.3541C69.2357 22.5854 58.1224 4.88982 40.3536 0.830025C22.5849 -3.22977 4.88935 7.88353 0.829551 25.6523C-3.23025 43.421 7.88306 61.1166 25.6518 65.1764C43.4206 69.2361 61.1161 58.1228 65.1759 40.3541Z" fill="url(#paint0_radial_47:956)"/>
            <path d="M33.1106 51.1128C43.1128 51.1128 51.2212 43.0044 51.2212 33.0022C51.2212 23 43.1128 14.8916 33.1106 14.8916C23.1084 14.8916 15 23 15 33.0022C15 43.0044 23.1084 51.1128 33.1106 51.1128Z" fill="url(#paint1_linear_47:956)"/>
            <path d="M33.1106 51.1128C43.1128 51.1128 51.2212 43.0044 51.2212 33.0022C51.2212 23 43.1128 14.8916 33.1106 14.8916C23.1084 14.8916 15 23 15 33.0022C15 43.0044 23.1084 51.1128 33.1106 51.1128Z" stroke="#8A92B2" stroke-width="4" stroke-miterlimit="10"/>
            <path d="M33.4302 20.894L33.2686 21.4463V37.4558L33.4302 37.6174L40.8648 33.2267L33.4302 20.894Z" fill="#EDEEEF"/>
            <path d="M33.4301 20.894L26 33.2267L33.4301 37.6174V29.8461V20.894Z" fill="white"/>
            <path d="M33.43 39.0271L33.3402 39.1348V44.841L33.43 45.1059L40.8691 34.6364L33.43 39.0271Z" fill="#EDEEEF"/>
            <path d="M33.4301 45.1059V39.0271L26 34.6364L33.4301 45.1059Z" fill="white"/>
            <path d="M40.8647 33.2267L33.4301 37.6174V29.8461L40.8647 33.2267Z" fill="#D5D8DB"/>
            <path d="M33.4301 29.8461V37.6174L26 33.2267L33.4301 29.8461Z" fill="#EDEEEF"/>
            </g>
            <defs>
            <radialGradient id="paint0_radial_47:956" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33.0034 33.0028) scale(41.0025)">
            <stop stop-color="#B3B6C4"/>
            <stop offset="0.99" stop-color="#8A95BD" stop-opacity="0"/>
            </radialGradient>
            <linearGradient id="paint1_linear_47:956" x1="1761.95" y1="1952.36" x2="3812.07" y2="3711.99" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stop-color="#8A92B2"/>
            <stop offset="1" stop-color="#C1C2C7"/>
            </linearGradient>
            <clipPath id="clip0_47:956">
            <rect width="66" height="66" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        network: {
            type: String,
            default: "ERC20"
        }
    }
}
</script>

<style scoped>
    
</style>