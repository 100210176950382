<template>
    <div class="fixed inset-0 z-10 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" aria-hidden="true"></div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform shadow-xl rounded-2xl bg-king-modal sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                <div class="flex justify-end item-center">
                    <button @click="$emit('close-modal')" type="button" class="text-white bg-transparent rounded-md hover:text-gray-300">
                        <span class="sr-only">Close</span>
                        <!-- Heroicon name: outline/x -->
                        <svg class="w-6 h-6 text-blue-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div class="px-10">
                    <div class="mt-3 sm:mt-5">
                        <h3 class="text-xl font-bold leading-6 text-white" id="modal-title">
                            <span class="text-green-500">Successfully</span> sent your tokens to the Bridge!
                        </h3>
                        <div class="flex items-center justify-center mt-4">
                            <p class="text-sm font-medium text-white">
                            Please write down the following information. You will need this information to claim your tokens on the target network. After you write down this information, switch to the target network on your wallet to claim your tokens.
                            </p>
                        </div>
                        <div class="flex flex-col px-8 py-5 mt-8 break-words bg-gray-700 rounded-lg shadow-inner">
                            <p class="text-gray-300">
                                Swap ID: <span class="text-sm font-semibold text-white">{{ swap_id }}</span>
                            </p>
                            <p class="text-gray-300">
                                Unique Identifier: <span class="text-sm font-semibold text-white">{{ unique_identifier }}</span>
                            </p>
                            <p class="text-gray-300">
                                Amount: <span class="text-sm font-semibold text-white">{{ swap_amount }}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(['isConnected'])
    },
    data() {
        return {
            swap_id: "",
            unique_identifier: "",
            swap_amount: ""
        }
    },
    mounted() {
        if (localStorage.kingshibaLatestSwapId) {
            this.swap_id = localStorage.kingshibaLatestSwapId;
            this.unique_identifier = localStorage.kingshibaLatestSwapTimestamp;
            this.swap_amount = localStorage.kingshibaLatestSwapNumTokens;
        }
    },
}
</script>