<template>
    <div class="flex items-center justify-between p-5">
      <a :href="scanUrl" target="_blank" class="text-xs text-white bg-transparent flex items-center hover:text-gray-400">View On BscScan</a>
      <button @click="addToMetamask" class="text-xs text-white bg-transparent flex items-center hover:text-gray-400">
        <span>Add To Metamask</span>
        <img class="ml-2 w-5 h-5" src="@/assets/metamask.png" alt="">
      </button>
    </div>
</template>

<script>
export default {
  props: {
    networkType: {
      type: String
    },
    tokenAddress: {
      type: String,
      default: "0x2859e4544c4bb03966803b044a93563bd2d0dd4d"
    },
    tokenSymbol: {
      type: String,
      default: "KINGSHIB"
    },
    tokenDecimals: {
      type: Number,
      default: 9
    }
  },
  computed: {
    scanUrl() {
      if (this.networkType == 'ERC20') {
        return `https://bscscan.com/address/${this.tokenAddress}`
      } else if (this.networkType == 'BEP20') {
        return `https://bscscan.com/address/${this.tokenAddress}`
      }
    }
  },
  methods: {
    async addToMetamask() {
      console.log(this.tokenAddress)
      const tokenAddress = this.tokenAddress;
      const tokenSymbol = this.tokenSymbol;
      const tokenDecimals = this.tokenDecimals;

      if (!window.ethereum)
        return this.$toast.error(
          "Make sure you using a web3 enabled browser like Metamask, TrustWallet etc."
        );

      // https://docs.metamask.io/guide/registering-your-token.html#code-free-example
      try {
        /* const wasAdded = */ await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: tokenAddress,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
            },
          },
        });

        // if (wasAdded) {
        //   this.$toast.success("Token contract added to MetaMask wallet!");
        // } else {
        //   this.$toast.error("Token contract was not added to MetaMask wallet.");
        // }
      } catch (error) {
      }
    }
  }
}
</script>