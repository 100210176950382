<template>
  <div
    class="fixed inset-0 z-10 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform shadow-xl rounded-2xl bg-king-modal sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6"
      >
        <div class="flex justify-end item-center">
          <button
            type="button"
            :disabled="isLoading"
            @click="$emit('close-modal')"
            class="text-white bg-transparent rounded-md hover:text-gray-300"
            :class="{
              'cursor-not-allowed bg-king-dark': isLoading
            }"
          >
            <span class="sr-only">Close</span>
            <!-- Heroicon name: outline/x -->
            <svg
              class="w-6 h-6 text-blue-700"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div v-if="isLoadingLocal" class="flex flex-col items-center justify-center p-10">
          <img class="w-32 h-32" src="../assets/logo.png" alt="">
          <h1 class="mt-5 text-2xl font-bold text-center text-king-header">FETCHING DATA...</h1>
        </div>
        <div v-else class="flex flex-col">
          <div>
              <h1 class="text-2xl font-bold text-king-header">Stake Your NFT</h1>
          </div>
          <div class="mt-4">
            <p class="text-sm text-white">
              Select the NFT's you want to stake
            </p>
            <p class="text-sm text-white">
              {{ selectedNftTokenIds.length }} NFT Selected
            </p>
            
            <div v-if="allUserNftTokens.length > 0" class="flex flex-col pt-5">
              <div
                v-for="(nft,index) in allUserNftTokens"
                :key="index"
                class="flex cursor-pointer"
                @click="selectedNft(nft.token_id)" 
              >
                <div 
                  class="flex items-center mb-4 w-full py-4"
                  :class="{
                      'bg-king-btn': selectedNftTokenIds.includes(nft.token_id),
                      'bg-gray-500 bg-opacity-40': !selectedNftTokenIds.includes(nft.token_id)
                    }" 
                >
                  <img
                    class="object-contain w-24 h-24"
                    :src="nft.image"
                  >
                  <h1 class="text-left text-white text-xl ml-2">{{ nft.nft_name }} #{{ nft.token_id }}</h1>
                </div>
              </div>
            </div>
            <div v-else class="flex items-center justify-center my-8">
              <h1 class="text-2xl font-bold text-king-header">No NFT's Found!</h1>
            </div>
          </div>
          <div class="flex">
            <button
              type="button"
              class="inline-flex items-center justify-center w-full px-4 py-2 mt-5 mr-4 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-king-btn hover:bg-king-dark"
              :class="{
                'cursor-not-allowed bg-king-dark': isLoading
              }"
              :disabled="isLoading"
              @click="stakeTokens"
            >
              Stake NFT
            </button>
            <button
              type="button"
              class="inline-flex items-center justify-center w-1/4 px-4 py-2 mt-5 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-king-btn hover:bg-king-dark"
              :class="{
                'cursor-not-allowed bg-king-dark': !isPastTimelock
              }"
              :disabled="!isPastTimelock"
              @click="unstakeTokens"
            >
              Unstake
            </button>
          </div>
          <template v-if="hasStakedTokens">
            <div v-if="!isPastTimelock">
              <p class="mt-5 font-semibold text-justify text-red-700">
                This farm has a {{ timelockDays }} day timelock. You originally staked at {{ timeUserOriginallyStaked }} and will be able to unstake your tokens after {{ timeUserCanUnstake }}.
              </p>
            </div>
          </template>
          <a
            href="https://oklg.io/"
            target="_blank"
            class="mt-8 text-base font-semibold text-center cursor-pointer text-king-header"
          >
            Powered by OKLG
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import BigNumber from "bignumber.js";
import Slider from "@vueform/slider";
import dayjs from "dayjs";
export default {
  components: {
    Slider
  },
  props: ["stakingContract", "isExpired"],
  data() {
    return {
        isLoadingLocal: true,
        isLoading: false,
        swapAmount: 0,
        swapPercentage: 0,
        stakingInfo: {},
        selectedNftTokenIds: [],
        allUserNftTokens: [],
    };
  },
  computed: {
    filteredStakingContracts() {
      return this.stakingContract
    },
    farmingTokenAddress() {
      return this.stakingContract.farmingTokenAddy;
    },
    tokenDecimals() {
      return this.stakingContract.currentTokenDecimals
    },
    canStake() {
      return this.stakingInfo.poolInfo.isStakedNft
        ? this.selectedNftTokenIds.length > 0
        : this.swapAmount > 0;
    },
    userStakingBalance() {
      return this.stakingInfo.poolInfo.isStakedNft
        ? this.stakingInfo.stakingTokenInfo.userBalance
        : new BigNumber(this.stakingInfo.stakingTokenInfo.userBalance)
            .div(
              new BigNumber(10).pow(this.stakingInfo.stakingTokenInfo.decimals)
            )
            .toFormat(0, BigNumber.ROUND_DOWN);
    },
    isPastTimelock() {
      if (!this.stakingInfo) return true;

      const timelockSeconds = this.stakingInfo.poolInfo.stakeTimeLockSec;
      if (!timelockSeconds) return true;

      const userStakedTime =
        this.stakingInfo.stakerInfo &&
        this.stakingInfo.stakerInfo.timeOriginallyStaked;
      if (!userStakedTime) return true;

      const isPastTime = dayjs(
        new BigNumber(userStakedTime).times(1e3).toNumber()
      )
        .add(timelockSeconds, "seconds")
        .isBefore(dayjs());
      if (isPastTime) return true;

      if (this.isExpired) return true;

      return false;
    },

    contractIsRemoved() {
      return this.stakingInfo.contractIsRemoved;
    },

    timelockDays() {
      const timelockSeconds = this.stakingInfo.poolInfo.stakeTimeLockSec;
      if (!timelockSeconds) return 0;
      return new BigNumber(timelockSeconds).div(60).div(60).div(24).toFormat();
    },

    hasStakedTokens() {
      return new BigNumber(this.stakingInfo.userStakingAmount).gt(0);
    },

    timeUserOriginallyStaked() {
      const userStakedTime = this.stakingInfo.stakerInfo.timeOriginallyStaked;
      return dayjs(new BigNumber(userStakedTime).times(1e3).toNumber()).format(
        "MMMM DD, YYYY HH:mm:ss"
      );
    },

    timeUserCanUnstake() {
      const timelockSeconds = this.stakingInfo.poolInfo.stakeTimeLockSec;
      const userStakedTime = this.stakingInfo.stakerInfo.timeOriginallyStaked;
      return dayjs(new BigNumber(userStakedTime).times(1e3).toNumber())
        .add(timelockSeconds, "seconds")
        .format("MMMM DD, YYYY HH:mm:ss");
    },
    userNftTokens() {
      return this.allUserNftTokens.length > 0 ? this.allUserNftTokens.length : 0
    }
  },
  async mounted() {
    try {
      this.stakingInfo = await this.getFaasStakingInfo(this.stakingContract.farmingTokenAddy)
       if (this.stakingInfo.poolInfo.isStakedNft) {
          this.allUserNftTokens = await this.getUserOwnedNfts({
            tokenAddress: this.stakingInfo.stakingTokenInfo.address
          })
        }
    } catch(err) {
      console.log(err)
    } finally {
      this.isLoadingLocal = false;
    }
  },
  methods: {
    ...mapActions([
      "init",
      "getFaasStakingInfo",
      "faasStakeTokens",
      "faasUnstakeTokens",
      "getUserOwnedNfts"
    ]),
    onChangeSlider(value) {
      document.getElementsByClassName(
        "slider-touch-area"
      )[0].innerText = `${value}%`;
    },
    maxAmount() {
      this.swapAmount = new BigNumber(this.stakingContract.currentTokenBalance)
        .div(new BigNumber(10).pow(this.tokenDecimals))
        .toFormat(2).replace(/,/g,'')
      this.swapPercentage = 100
    },
    async stakeTokens() {
      try {
        if (!this.canStake) return;
        this.isLoading = true
        await this.faasStakeTokens({
          farmingContractAddress: this.farmingTokenAddress,
          stakingContractAddress: this.stakingInfo.stakingTokenInfo.address,
          amountTokens: 0,
          nftTokenIds: this.selectedNftTokenIds,
        })
        this.$notify({
          type: "success",
          text: "Successfully staked your tokens!",
        });
        await this.init(true)
      } catch (err) {
        this.$notify({
          type: "error",
          text: err.message,
        });
      } finally {
        this.isLoading = false
      }
    },
    async unstakeTokens() {
      try {
          this.isLoading = true
          await this.faasUnstakeTokens({
            farmingContractAddress: this.farmingTokenAddress,
          })
        this.$notify({
          type: "success",
          text: "Successfully unstaked all tokens!",
        });
      } catch (err) {
        console.error("Error unstaking tokens", err);
        this.$notify({
          type: "error",
          text: err.message,
        });
      } finally {
        this.isLoading = false
        await this.init(true)
      }
    },
    selectedNft(id) {
      let index = this.selectedNftTokenIds.findIndex((i) => {
        return i == id
      })
      if (index == -1) {
        this.selectedNftTokenIds.push(id)
      } else {
        this.selectedNftTokenIds.splice(index,1)
      }
    }
  },
};
</script>