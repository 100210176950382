<template>
  <div
    class="fixed inset-0 z-10 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
        aria-hidden="true"
      ></div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform shadow-xl rounded-2xl bg-king-modal sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
      >
        <div class="flex justify-end item-center">
            <button @click="$emit('close-modal')" type="button" class="text-white bg-transparent rounded-md hover:text-gray-300">
                <span class="sr-only">Close</span>
                <!-- Heroicon name: outline/x -->
                <svg class="w-6 h-6 text-blue-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </div>
        <div>
          <div class="mt-3 text-center sm:mt-5">
            <h3
              class="text-lg font-medium leading-6 text-king-danger"
              id="modal-title"
            >
              Refund Tokens
            </h3>
            <div class="mt-2">
              <p class="text-white text-md">
                Are you sure you want to refund your tokens to this network? <br>
                <span class="font-semibold text-king-danger">You cannot claim them anymore on the target network.</span>
              </p>
            </div>
          </div>
        </div>
        <div
          class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
        >
          <button
            type="button"
            class="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:text-gray-500 sm:mt-0 sm:w-auto sm:text-sm"
            @click="$emit('refund', false)"
          >
            Cancel
          </button>
          <button
            type="button"
            class="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-king-btn hover:bg-king-dark sm:ml-3 sm:w-auto sm:text-sm"
            @click="$emit('refund', true)"
          >
            Refund
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
