<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="200px"
      height="200px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      class="w-8 h-8 ml-2 animate-spin"
    >
      <circle
        cx="50"
        cy="50"
        r="32"
        stroke-width="8"
        stroke="currentColor"
        stroke-dasharray="50.26548245743669 50.26548245743669"
        fill="none"
        stroke-linecap="round"
        transform="matrix(1,0,0,1,0,0)"
        style="transform:matrix(1, 0, 0, 1, 0, 0);animation-play-state:paused"
      ></circle>
      <!-- generated by https://loading.io/ -->
    </svg>
  </div>
</template>