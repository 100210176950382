<template>
  <div class="relative flex flex-col w-full bg-king-bg bg-opacity-5 md:w-1/2 md:mr-10 rounded-xl mt-48 sm:mt-0">
    <img class="absolute w-auto left-0 right-0 h-28 mx-auto inset-x-36 -inset-y-14" src="../assets/king-stake.png" alt="">
    <div class="flex items-center justify-center h-32 text-center text-white bg-black bg-opacity-60 rounded-t-xl">
      <h1 class="mt-10 text-2xl font-bold text-king-header"><span class="text-base font-normal text-white ">APR: </span>{{ stakedTokenSymbol == rewardTokenSymbol ? `${stakingApr || 0}%` : 'APR Coming Soon' }}</h1>
    </div>
    <div class="flex flex-col items-center p-5">
      <h1 class="text-2xl font-semibold text-king-header">King Shib Staking</h1>
      <p class="text-xs text-white">Stake your tokens to earn $KINGSHIB</p>
      <div class="mt-5 text-center">
        <h1 v-if="stakedBalance" class="text-xl font-semibold text-king-header">{{stakedBalance}} {{stakedTokenSymbol}}</h1>
        <h1 v-else class="text-xl font-semibold text-king-header">0</h1>
        <p class="text-xs text-white">Staked</p>
      </div>
      <div class="mt-5 text-center">
        <h1 v-if="stakedBalance" class="text-xl font-semibold text-king-header">${{stakedInUsd}}</h1>
        <h1 v-else class="text-xl font-semibold text-king-header">$0</h1>
        <p class="text-xs text-white">Staked (USD)</p>
      </div>
      <div class="mt-5 text-center">
        <div v-if="isInFarm">
          <div v-if="!contractIsRemoved">
            <h1 class="text-xl font-semibold text-king-header">{{ amountUnharvested[1] }} {{ rewardTokenSymbol }}</h1>
          </div>
          <h1 v-else class="text-xl font-semibold text-king-header">{{ amountUnharvested[1] }} {{ rewardTokenSymbol }}</h1>
        </div>
        <h1 v-else class="text-xl font-semibold text-king-header">0</h1>
        <p class="text-xs text-white">Earned</p>
      </div>
      <div class="mt-5 text-center">
        <div v-if="isInFarm">
          <div v-if="!contractIsRemoved">
            <h1 class="text-xl font-semibold text-king-header">${{ earnedInUsd }}</h1>
          </div>
          <h1 v-else class="text-xl font-semibold text-king-header">${{ earnedInUsd }}</h1>
        </div>
        <h1 v-else class="text-xl font-semibold text-king-header">$0</h1>
        <p class="text-xs text-white">Earned (USD)</p>
      </div>
      <div class="mt-5 text-center">
        <h1 class="text-xl font-semibold text-king-header">{{timelockDays}} days</h1>
        <p class="text-xs text-white">Timelock</p>
      </div>
      <div class="my-5 text-center">
        <h1 class="text-xl font-semibold text-king-header">{{ estimateExpirationTime }}</h1>
        <p class="text-xs text-white">Expiration</p>
      </div>
      <div class="text-center">
        <h1 class="text-xl font-semibold text-king-header">{{ stakedTokenSymbol == rewardTokenSymbol ? `${stakingApr || 0}%` : 'APR Coming Soon' }}</h1>
        <p class="text-xs text-white">APR</p>
      </div>
      <button
        type="button"
        class="inline-flex items-center justify-center w-full px-4 py-2 mt-10 text-base font-medium border border-transparent rounded-md shadow-sm text-king-txt bg-king-btn hover:bg-king-dark"
        @click="kingStakeModal = true"
      >
        Stake / Unstake
      </button>
      <button
        v-if="stakingContract.farmingTokenBalance > 0 && isPastTimelock"
        type="button"
        class="inline-flex items-center justify-center w-full px-4 py-2 mt-10 text-base font-medium border border-transparent rounded-md shadow-sm text-king-txt bg-king-btn hover:bg-king-dark"
        @click="onClaimRewardModal = true"
      >
        Claim Rewards
      </button>
    </div>
    <Footer :network-type="smartContractType" :token-address="smartContractType == 'BEP20' ? '0x84f4f7cdb4574c9556a494dab18ffc1d1d22316c' : '0x84f4f7cdb4574c9556a494dab18ffc1d1d22316c'" :token-decimals="18" token-symbol="KINGSHIB"/>
    <notifications position="top right" />
    <king-stake-modal v-if="kingStakeModal" @close-modal="kingStakeModal = false" :staking-contract="stakingContract" :is-expired="isFarmExpired" />
    <claim-rewards-modal
      v-if="onClaimRewardModal"
      @close-modal="onClaimRewardModal = false"
      @claim="harvestTokens"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";
import BigNumber from "bignumber.js";
import MTGYFaaSToken from "../factories/web3/MTGYFaaSToken";
import KingStakeModal from "./KingStakeModal.vue"
import Footer from "./Footer.vue"
import DexUtils from "@/factories/DexUtils";
import MTGYDataUtils from "@/factories/MTGYDataUtils";
import ClaimRewardsModal from "./ClaimRewardsModal.vue"
import axios from "axios";
export default {
  name: "KingStaking",
  props: ["stakingContract"],
  components: {
    KingStakeModal,
    Footer,
    ClaimRewardsModal
  },
  data() {
    return {
      isLoading: false,
      isInFarm: false,
      kingStakeModal: false,
      tokensStakedPerBlock: [],
      tokensRewardedPerBlock: [],
      amountUnharvested: [],
      totalTokensStaked: [],
      rewardsTokenPriceUSD: 0,
      stakingTokenPriceUSD: 0,
      amountEarned: 0,
      pricePerUsd: 0,
      onClaimRewardModal: false
    }
  },
  computed: {
    ...mapGetters([
      "isConnected",
      "initLoading",
      "activeNetwork",
      "web3",
      "selectedTokenAddress",
      "tokenStakingContracts",
      "currentBlock"
    ]),
    isFrozen() {
      return [
        "0xFB7D9c478b2F8B1d07Ad196076c881f11F370Ca4".toLowerCase(),
      ].includes(this.stakingContract.farmingTokenAddy.toLowerCase());
    },
    blocksPerDay() {
      return this.activeNetwork ? this.activeNetwork.blocks_per_day : 0
    },
    timelockDays() {
      const timelockSeconds = this.stakingContract.poolInfo.stakeTimeLockSec;
      if (!timelockSeconds) return 0;
      return new BigNumber(timelockSeconds).div(60).div(60).div(24).toFormat();
    },
    estimateExpirationTime() {
      const currentBlock = this.currentBlock;
      const lastBlock = this.stakingContract.lastStakableBlock;
      const blocksPerSecond = new BigNumber(this.blocksPerDay)
        .div(24)
        .div(60)
        .div(60);
      if (new BigNumber(lastBlock).lt(currentBlock)) return;
      const secondsFromNow = new BigNumber(
        new BigNumber(lastBlock).minus(currentBlock)
      ).div(blocksPerSecond);
      return dayjs().add(secondsFromNow, "seconds").format("MMM D, YYYY HH:mm");
    },
    filterStakingLength() {
      return this.stakingContract.length
    },
    rewardTokenSymbol() {
      return this.stakingContract.rewardTokenSymbol;
    },
    stakedTokenSymbol() {
      if (this.stakingContract) {
        return this.stakingContract.currentTokenSymbol;
      }
    },
    tokenDecimals() {
      return this.stakingContract.currentTokenDecimals;
    },
    farmingTokenAddress() {
      return this.stakingContract.farmingTokenAddy;
    },
    contractIsRemoved() {
      return this.stakingContract.contractIsRemoved;
    },
    rewardsTokenDecimals() {
      return this.stakingContract.rewardTokenDecimals;
    },
    stakedBalance() {
      return this.stakingContract.poolInfo.isStakedNft
        ? this.stakingContract.farmingTokenBalance
        : new BigNumber(this.stakingContract.farmingTokenBalance)
            .div(new BigNumber(10).pow(this.stakingContract.farmingTokenDecimals))
            .times(
              new BigNumber(10).pow(
                this.stakingContract.farmingTokenDecimals - this.tokenDecimals
              )
            )
            .toFormat(0, BigNumber.ROUND_DOWN);
    },
    stakingApr() {
      const blocksPerDay = new BigNumber(this.blocksPerDay);
      const userStakedTokens = new BigNumber(
        new BigNumber(this.stakingContract.farmingTokenBalance).gt(0)
          ? this.stakingContract.farmingTokenBalance
          : new BigNumber(this.totalTokensStaked[0]).div(500)
      );
      const totalStakedBalanceUSD = new BigNumber(
        this.totalTokensStaked[0] || 0
      )
        .times(this.stakingTokenPriceUSD)
        .div(new BigNumber(10).pow(this.stakingContract.currentTokenDecimals));
      const perBlockRewardedAmountUSD = new BigNumber(
        this.tokensRewardedPerBlock[0] || 0
      )
        .times(this.rewardsTokenPriceUSD)
        .div(new BigNumber(10).pow(this.stakingContract.rewardTokenDecimals));
      if (totalStakedBalanceUSD.eq(0) || perBlockRewardedAmountUSD.eq(0))
        return 0;
      const tokensStakablePerYear = perBlockRewardedAmountUSD
        .times(blocksPerDay)
        .times(365);
      const userStakablePerYear = userStakedTokens
        .div(totalStakedBalanceUSD)
        .times(tokensStakablePerYear);
      const apr = userStakablePerYear
        .div(userStakedTokens)
        .times(100)
        .toFormat(2);
      return apr;
    },
    isFarmExpired() {
      return (
        this.stakingContract.lastStakableBlock &&
        new BigNumber(this.stakingContract.lastStakableBlock).lte(this.currentBlock)
      );
    },
    isPastTimelock() {
      const stakingInfo = this.stakingContract;
      if (!stakingInfo) return true;

      const timelockSeconds = stakingInfo.poolInfo.stakeTimeLockSec;
      if (!timelockSeconds) return true;

      const userStakedTime =
        stakingInfo.stakerInfo && stakingInfo.stakerInfo.timeOriginallyStaked;
      if (!userStakedTime) return true;

      const isPastTime = dayjs(
        new BigNumber(userStakedTime).times(1e3).toNumber()
      )
        .add(timelockSeconds, "seconds")
        .isBefore(dayjs());
      if (isPastTime) return true;

      if (this.isFarmExpired) return true;

      return false;
    },
    smartContractType() {
      if (this.isConnected && !this.initLoading) {
        if (this.activeNetwork.network_id == 1) {
          return "ERC20";
        } else if (this.activeNetwork.network_id == 56) {
          return "BEP20";
        }
      } else {
        return "ERC20";
      }
    },
    earnedInUsd() {
      return new BigNumber(this.pricePerUsd * this.amountEarned).toFormat(5);
    },
    stakedInUsd() {
      const stakedBalance = new BigNumber(this.stakingContract.farmingTokenBalance)
            .div(new BigNumber(10).pow(this.stakingContract.farmingTokenDecimals))
            .times(
              new BigNumber(10).pow(
                this.stakingContract.farmingTokenDecimals - this.tokenDecimals
              )
            )
      return new BigNumber(this.pricePerUsd * stakedBalance).toFormat(5)
    }
  },
  async mounted() {
    await this.getAllStakingContracts()
    await this.getUnharvestedTokens()
    await this.getBothTokenPricesUSD()
    await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=king-shiba&vs_currencies=usd").then(({data}) => {
      this.pricePerUsd = data['king-shiba'].usd
    })
  },
  methods: {
    ...mapActions([
      "faasHarvestTokens",
      "getAllStakingContracts"
    ]),
    async getUnharvestedTokens() {
      try {
        const stakingContract = MTGYFaaSToken(
          this.web3.instance,
          this.farmingTokenAddress
        );
        const stakingBalance = await stakingContract.methods
          .balanceOf(this.web3.address)
          .call();
        this.isInFarm = stakingBalance && stakingBalance > 0;

        const [amountUnharvested, pool] = await Promise.all([
          stakingContract.methods.calcHarvestTot(this.web3.address).call(),
          stakingContract.methods.pool().call(),
        ]);
        this.amountUnharvested = [
          amountUnharvested,
          new BigNumber(amountUnharvested)
            .div(new BigNumber(10).pow(this.rewardsTokenDecimals))
            .toFormat(2),
        ];
        this.amountEarned = new BigNumber(amountUnharvested)
            .div(new BigNumber(10).pow(this.rewardsTokenDecimals))
        this.totalTokensStaked = [
          pool.totalTokensStaked,
          new BigNumber(pool.totalTokensStaked)
            .div(new BigNumber(10).pow(this.tokenDecimals))
            .toFormat(2),
        ];
        this.tokensRewardedPerBlock = [
          pool.perBlockNum,
          new BigNumber(pool.perBlockNum)
            .div(new BigNumber(10).pow(this.tokenDecimals))
            .toFormat(2),
        ];
      } catch (err) {
        console.error(`Error getting unharvested`, err);
        true;
      }
    },
    async harvestTokens() {
      try {
        this.isLoading = true
        this.onClaimRewardModal = false
        if (new BigNumber(this.stakingContract.farmingTokenBalance).lte(0)) {
          this.$notify({
            type: "error",
            text: "You do not have any rewards to harvest.",
          });
          return
        }

        await this.faasHarvestTokens({
          farmingContractAddress: this.farmingTokenAddress,
        })
        this.$notify({
          type: "success",
          text: "Successfully claimed rewards!",
        });
        await this.init(true);
      } catch (err) {
        this.$notify({
          type: "error",
          text: err.message,
        });
      } finally {
        this.isLoading = false
      }
    },
    async getBothTokenPricesUSD() {
      // TODO check if either token is an LP token and handle it separately
      // 1. get both staking and rewards token information
      const [stakingTokenAddress, stakingTokenSymbol] = [
        this.stakingContract.tokenAddy,
        this.stakingContract.currentTokenSymbol,
      ];
      const [rewardsTokenAddress, rewardsTokenSymbol] = [
        this.stakingContract.rewardAddy,
        this.stakingContract.rewardTokenSymbol,
      ];
      // 2. check if BSC and use DexUtils to get price in USD, otherwise use api.moontography.com
      if (this.activeNetwork.short_name === "bsc") {
        const [sp, rp] = await Promise.all([
          DexUtils.getTokenPrice(stakingTokenAddress),
          DexUtils.getTokenPrice(rewardsTokenAddress),
        ]);
        this.stakingTokenPriceUSD = sp;
        this.rewardsTokenPriceUSD = rp;
      } else {
        const [sp, rp] = await Promise.all([
          MTGYDataUtils.getTokenPriceUSD(stakingTokenSymbol),
          MTGYDataUtils.getTokenPriceUSD(rewardsTokenSymbol),
        ]);
        this.stakingTokenPriceUSD = sp;
        this.rewardsTokenPriceUSD = rp;
      }
    },
  }
}
</script>