<template>
  <video class="fixed" crossorigin="anonymous" playsinline="" preload="auto" muted="" loop="" autoplay="" tabindex="-1" width="100%" height="100%" src="https://video.wixstatic.com/video/df6cf8_c87009461fe04666b7039d17177e265d/1080p/mp4/file.mp4" style="width: 100%; height: 100%; object-fit: cover; object-position: center center; opacity: 1; z-index: -1;"></video>
  <div class="min-h-full">
    <nav class="">
      <div class="px-4 pt-4 mx-auto max-w-8xl sm:px-6 lg:px-20">
        <div class="flex flex-col justify-between h-28 sm:flex-row">
          <div class="flex">
            <div class="flex items-center flex-shrink-0 mx-auto mb-8 sm:mx-0 sm:mb-0">
              <img width="70" height="70" src="./assets/logo.png" alt="" />
            </div>
          </div>
          <div class="flex flex-col items-center justify-between sm:flex-row">
            <div
              v-if="isConnected && !initLoading && swap"
              class="flex mx-10 mb-6 text-xs font-semibold text-white sm:text-sm sm:flex-row sm:mb-0"
            >
              <div class="p-1.5 px-2 rounded-tl-sm rounded-bl-sm bg-king-btn">
                Connected
              </div>
              <div class="p-1.5 px-2 rounded-tr-sm rounded-br-sm bg-gray-700">
                {{ start_and_end(web3.address) }}
              </div>
            </div>
            <div class="flex flex-row mx-0">
              <a class="mx-3 text-lg font-semibold text-king-header" target="_blank" href="https://twitter.com/KINGSHIBABSC">Twitter</a>
              <a class="mx-3 mb-5 mr-0 text-lg font-semibold text-king-header sm:mr-14 sm:mb-0" target="_blank" href="https://t.me/KINGOFSHIBA">Telegram</a>
            </div>
            <div class="flex flex-row mx-0 my-5 sm:my-0">
              <button
                v-if="!isConnected"
                type="button"
                class="inline-flex items-center px-4 py-2 text-base font-medium border border-transparent rounded-md shadow-sm text-king-txt bg-king-btn hover:bg-king-dark"
                @click="init()"
              >
                Connect Wallet
                <Spinner v-if="initLoading" />
              </button>
              <button
                v-if="isConnected && initLoading"
                type="button"
                class="inline-flex items-center px-4 py-2 text-base font-medium border border-transparent rounded-md shadow-sm text-king-txt bg-king-btn hover:bg-king-dark"
              >
                Connecting Wallet
                <Spinner />
              </button>
              <button
                v-else-if="isConnected"
                type="button"
                class="inline-flex items-center px-4 py-2 text-base font-medium border border-transparent rounded-md shadow-sm text-king-txt bg-king-btn hover:bg-king-dark"
                @click="disconnect()"
              >
                Disconnect Wallet
              </button>
              <a href="/" class="inline-flex items-center px-4 py-2 ml-5 text-base font-medium border border-transparent rounded-md shadow-sm text-king-txt bg-king-btn hover:bg-king-dark">Bridge</a>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div class="px-12 mt-20 py-18 md:px-">
      <main v-if="isLoadingLocal">
        <div class="flex flex-col justify-center max-w-6xl mx-auto md:flex-row">
          <div class="relative flex flex-col w-full mx-auto mt-32 bg-king-bg bg-opacity-5 md:w-1/2 md:mr-10 rounded-xl sm:mt-0">
            <img class="absolute left-0 right-0 w-auto mx-auto h-28 inset-x-36 -inset-y-14" src="./assets/king-stake.png" alt="">
            <div class="flex items-center justify-center h-32 text-center text-white bg-black bg-opacity-60 rounded-t-xl">
              <h1 class="mt-10 text-2xl font-bold text-king-header"><span class="text-base font-normal text-white ">APR: </span>No Data</h1>
            </div>
            <div class="flex flex-col items-center p-5">
              <h1 class="text-2xl font-semibold text-king-header">King Shib Staking</h1>
              <p class="text-xs text-white">Stake your tokens to earn $KINGSHIB</p>
              <div class="mt-5 text-center">
                <h1 class="text-xl font-semibold text-king-header">No Data</h1>
                <p class="text-xs text-white">Staked</p>
              </div>
              <div class="mt-5 text-center">
                <h1 class="text-xl font-semibold text-king-header">No Data</h1>
                <p class="text-xs text-white">Staked (USD)</p>
              </div>
              <div class="mt-5 text-center">
                <h1 class="text-xl font-semibold text-king-header">No Data</h1>
                <p class="text-xs text-white">Earned</p>
              </div>
              <div class="mt-5 text-center">
                <h1 class="text-xl font-semibold text-king-header">No Data</h1>
                <p class="text-xs text-white">Timelock</p>
              </div>
              <div class="my-5 text-center">
                <h1 class="text-xl font-semibold text-king-header">No Data</h1>
                <p class="text-xs text-white">Expiration</p>
              </div>
              <div class="text-center">
                <h1 class="text-xl font-semibold text-king-header">No Data</h1>
                <p class="text-xs text-white">APR</p>
              </div>
              <button
                v-if="!isConnected"
                type="button"
                class="inline-flex items-center justify-center w-full px-4 py-2 mt-10 text-base font-medium border border-transparent rounded-md shadow-sm text-king-txt bg-king-btn hover:bg-king-dark"
                @click="init()"
              >
                Connect Wallet
                <Spinner v-if="initLoading" />
              </button>
              <button
                v-if="isConnected && initLoading"
                type="button"
                class="inline-flex items-center justify-center w-full px-4 py-2 mt-10 text-base font-medium border border-transparent rounded-md shadow-sm text-king-txt bg-king-btn hover:bg-king-dark"
              >
                Connecting Wallet
                <Spinner />
              </button>
              <button
                v-else-if="isConnected"
                type="button"
                class="inline-flex items-center justify-center w-full px-4 py-2 mt-10 text-base font-medium border border-transparent rounded-md shadow-sm text-king-txt bg-king-btn hover:bg-king-dark"
                @click="kingStakeModal = true"
              >
                Stake / Unstake
              </button>
            </div>
          </div>
          <div class="relative flex flex-col w-full mx-auto mt-32 bg-king-bg bg-opacity-5 md:w-1/2 md:mr-10 rounded-xl sm:mt-0">
            <img class="absolute left-0 right-0 w-auto mx-auto h-28 inset-x-36 -inset-y-14" src="./assets/nft-stake.png" alt="">
            <div class="flex items-center justify-center h-32 text-center text-white bg-black bg-opacity-60 rounded-t-xl">
              <h1 class="mt-10 text-2xl font-bold text-king-header"><span class="text-base font-normal text-white ">APR: </span>No Data</h1>
            </div>
            <div class="flex flex-col items-center p-5">
              <h1 class="text-2xl font-semibold text-king-header">King NFT Staking</h1>
              <p class="text-xs text-white">Stake your NFT to earn $KINGSHIB</p>
              <div class="mt-5 text-center">
                <h1 class="text-xl font-semibold text-king-header">No Data</h1>
                <p class="text-xs text-white">NFT's Staked</p>
              </div>
              <div class="mt-5 text-center">
                <h1 class="text-xl font-semibold text-king-header">No Data</h1>
                <p class="text-xs text-white">Value USD POOL</p>
              </div>
              <div class="mt-5 text-center">
                <h1 class="text-xl font-semibold text-king-header">No Data</h1>
                <p class="text-xs text-white">Earned</p>
              </div>
              <div class="mt-5 text-center">
                <h1 class="text-xl font-semibold text-king-header">No Data</h1>
                <p class="text-xs text-white">Timelock</p>
              </div>
              <div class="my-5 text-center">
                <h1 class="text-xl font-semibold text-king-header">No Data</h1>
                <p class="text-xs text-white">Expiration</p>
              </div>
              <div class="text-center">
                <h1 class="text-xl font-semibold text-king-header">No Data</h1>
                <p class="text-xs text-white">APR</p>
              </div>
              <button
                v-if="!isConnected"
                type="button"
                class="inline-flex items-center justify-center w-full px-4 py-2 mt-10 text-base font-medium border border-transparent rounded-md shadow-sm text-king-txt bg-king-btn hover:bg-king-dark"
                @click="init()"
              >
                Connect Wallet
                <Spinner v-if="initLoading" />
              </button>
              <button
                v-if="isConnected && initLoading"
                type="button"
                class="inline-flex items-center justify-center w-full px-4 py-2 mt-10 text-base font-medium border border-transparent rounded-md shadow-sm text-king-txt bg-king-btn hover:bg-king-dark"
              >
                Connecting Wallet
                <Spinner />
              </button>
              <button
                v-else-if="isConnected"
                type="button"
                class="inline-flex items-center justify-center w-full px-4 py-2 mt-10 text-base font-medium border border-transparent rounded-md shadow-sm text-king-txt bg-king-btn hover:bg-king-dark"
                @click="kingStakeModal = true"
              >
                Stake / Unstake
              </button>
            </div>
          </div>
          <div class="relative flex flex-col w-full mx-auto mt-32 bg-king-bg bg-opacity-5 md:w-1/2 md:mr-10 md:mt-0 rounded-xl mb-14 sm:mb-0">
            <img class="absolute left-0 right-0 w-auto h-24 mx-auto mt-3 inset-x-36 -inset-y-14" src="./assets/shib-stake.png" alt="">
            <div class="flex items-center justify-center h-32 text-center text-white bg-black bg-opacity-60 rounded-t-xl">
              <h1 class="mt-10 text-2xl font-bold text-king-header"><span class="text-base font-normal text-white ">APR: </span>No Data</h1>
            </div>
            <div class="flex flex-col items-center p-5">
              <h1 class="text-2xl font-semibold text-king-header">Shiba Inu Staking</h1>
              <p class="text-xs text-white">Stake your tokens to earn $SHIB</p>
              <div class="mt-5 text-center">
                <h1 class="text-xl font-semibold text-king-header">No Data</h1>
                <p class="text-xs text-white">Staked</p>
              </div>
              <div class="mt-5 text-center">
                <h1 class="text-xl font-semibold text-king-header">No Data</h1>
                <p class="text-xs text-white">Staked (USD)</p>
              </div>
              <div class="mt-5 text-center">
                <h1 class="text-xl font-semibold text-king-header">No Data</h1>
                <p class="text-xs text-white">Earned</p>
              </div>
              <div class="mt-5 text-center">
                <h1 class="text-xl font-semibold text-king-header">No Data</h1>
                <p class="text-xs text-white">Timelock</p>
              </div>
              <div class="my-5 text-center">
                <h1 class="text-xl font-semibold text-king-header">No Data</h1>
                <p class="text-xs text-white">Expiration</p>
              </div>
              <div class="text-center">
                <h1 class="text-xl font-semibold text-king-header">No Data</h1>
                <p class="text-xs text-white">APR</p>
              </div>
              <button
                v-if="!isConnected"
                type="button"
                class="inline-flex items-center justify-center w-full px-4 py-2 mt-10 text-base font-medium border border-transparent rounded-md shadow-sm text-king-txt bg-king-btn hover:bg-king-dark"
                @click="init()"
              >
                Connect Wallet
                <Spinner v-if="initLoading" />
              </button>
              <button
                v-if="isConnected && initLoading"
                type="button"
                class="inline-flex items-center justify-center w-full px-4 py-2 mt-10 text-base font-medium border border-transparent rounded-md shadow-sm text-king-txt bg-king-btn hover:bg-king-dark"
              >
                Connecting Wallet
                <Spinner />
              </button>
              <button
                v-else-if="isConnected"
                type="button"
                class="inline-flex items-center justify-center w-full px-4 py-2 mt-10 text-base font-medium border border-transparent rounded-md shadow-sm text-king-txt bg-king-btn hover:bg-king-dark"
                @click="shibStakeModal = true"
              >
                Stake
              </button>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-center">
          <a
            href="https://oklg.io/"
            target="_blank"
            class="my-20 text-base font-semibold text-center text-white cursor-pointer"
          >
            Powered by OKLG
          </a>
        </div>
      </main>
      <main-staking v-else :filtered-staking-contracts="filteredStakingContracts"/>
    </div>
    <notifications position="top right" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BigNumber from "bignumber.js";
import NetworkHeader from "./components/NetworkHeader.vue";
import Spinner from "./components/Spinner.vue";
import Slider from "@vueform/slider";
import dayjs from "dayjs";
import MainStaking from "./components/MainStaking.vue"

export default {
  name: "App",
  components: {
    NetworkHeader,
    Spinner,
    Slider,
    MainStaking
  },
  data() {
    return {
      isLoading: false,
      isLoadingLocal: true,
      swapAmount: 0,
      manualClaimModal: false,
      successfullySentModal: false,
      onRefundModal: false,
      swap_id: "",
      unique_identifier: "",
      swap_amount: 0,
      swapPercentage: 0,
      kingStakeModal: false,
      shibStakeModal: false
    };
  },
  computed: {
    ...mapGetters([
      "isConnected",
      "initLoading",
      "activeNetwork",
      "web3",
      "swap",
      "selectedTokenAddress",
      "tokenStakingContracts",
      "currentBlock"
    ]),
    smartContractType() {
      if (this.isConnected && !this.initLoading) {
        if (this.activeNetwork.network_id == 1) {
          return "ERC20";
        } else if (this.activeNetwork.network_id == 56) {
          return "BEP20";
        }
      } else {
        return "ERC20";
      }
    },
    blocksPerDay() {
      return this.activeNetwork ? this.activeNetwork.blocks_per_day : 0
    },
    swapAmountFormatted() {
      if (!this.swapAmount) {
        return 0;
      } else {
        return this.swapAmount;
      }
    },
    isAddyValid() {
      return (
        this.selectedTokenAddress &&
        this.web3.instance &&
        this.web3.instance.utils.isAddress(this.selectedTokenAddress)
      );
    },
    filteredStakingContracts() {
      return this.tokenStakingContracts
        .slice(0)
        .filter((c) => {
          return (
            (c.lastStakableBlock &&
              new BigNumber(c.lastStakableBlock).gt(this.currentBlock)) ||
            new BigNumber(c.farmingTokenBalance).gt(0)
          );
        })
        .filter(
          (c) =>
            !(
              c.contractIsRemoved && new BigNumber(c.farmingTokenBalance).lte(0)
            )
        );
    },
    timelockDays() {
      const timelockSeconds = this.filteredStakingContracts[0].poolInfo.stakeTimeLockSec;
      if (!timelockSeconds) return 0;
      return new BigNumber(timelockSeconds).div(60).div(60).div(24).toFormat();
    },
    estimateExpirationTime() {
      const currentBlock = this.currentBlock;
      const lastBlock = this.filteredStakingContracts[0].lastStakableBlock;
      const blocksPerSecond = new BigNumber(this.blocksPerDay)
        .div(24)
        .div(60)
        .div(60);
      if (new BigNumber(lastBlock).lt(currentBlock)) return;
      const secondsFromNow = new BigNumber(
        new BigNumber(lastBlock).minus(currentBlock)
      ).div(blocksPerSecond);
      return dayjs().add(secondsFromNow, "seconds").format("MMM D, YYYY HH:mm");
    },
    filterStakingLength() {
      return this.filteredStakingContracts.length
    },
    rewardTokenSymbol() {
      return this.filteredStakingContracts[0].rewardTokenSymbol;
    },
    stakedTokenSymbol() {
      return this.filteredStakingContracts[0].currentTokenSymbol;
    },
  },
  watch: {
    swapPercentage(value) {
      const currentBalance = this.swap.token.userBalance / Math.pow(10, this.swap.token.decimals)
      if (currentBalance = 0) {
        this.swapAmount = 0
      } else {
        this.swapAmount = ((value * currentBalance) / 100).toFixed()
      }
    },
    swapAmount(value) {
      this.swapPercentage = Math.round(100*value/(this.swap.token.userBalance / Math.pow(10, this.swap.token.decimals)))
    },
    async isAddyValid(isValid) {
      if (isValid) await this.lookUpTokenStakingContracts();
    },
    filterStakingLength(value) {
      if (value > 0) {
        this.isLoadingLocal = false
      }
    },
    initLoading(value) {
      this.isLoadingLocal = value
    },
    isConnected(value) {
      if (!value) {
        this.isLoadingLocal = true
      }
    }
    
  },
  async mounted() {
    await this.init(true)
  },
  methods: {
    ...mapActions([
      "init",
      "disconnect",
      "getAllStakingContracts"
    ]),
    start_and_end(str) {
      return str.substr(0, 6) + "..." + str.substr(str.length - 4, str.length);
    },
    userBalanceFormatted(tokenInfo) {
      return new BigNumber(tokenInfo.userBalance)
        .div(new BigNumber(10).pow(tokenInfo.decimals))
        .toFormat(2);
    },
    async lookUpTokenStakingContracts() {
      try {
        await this.getAllStakingContracts()
      } catch (err) {
      } finally {
        this.isLoadingLocal = false;
      }
    },
    onChangeSlider(value) {
      document.getElementsByClassName(
        "slider-touch-area"
      )[0].innerText = `${value}%`;
    },
    maxAmount() {
      this.swapAmount = this.userBalanceFormatted(this.swap.token).replace(/,/g,'')
      this.swapPercentage = 100
    }
  },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
<style>
body {
  background: #151519;
}
.king-slider {
  --slider-bg: #d1d5db;
  --slider-connect-bg: #dd5200;
  --slider-connect-bg-disabled: #9ca3af;
  --slider-height: 6px;
  --slider-vertical-height: 300px;
  --slider-radius: 9999px;

  --slider-handle-bg: #c4c4c4;
  --slider-handle-border: 0;
  --slider-handle-width: 40px;
  --slider-handle-height: 20px;
  --slider-handle-radius: 5px;
  --slider-handle-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.32);
  --slider-handle-shadow-active: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.42);
  --slider-handle-ring-width: 3px;
  --slider-handle-ring-color: transparent;
}
.slider-touch-area {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #dd5200;
  font-size: 12px;
}
</style>
