<template>
    <main>
      <div class="flex flex-col justify-center max-w-6xl mx-auto md:flex-row">
        <king-staking v-if="smartContractType == 'BEP20'" :staking-contract="kingShibContract" />
        <nft-staking v-if="smartContractType == 'BEP20'" :staking-contract="kingNftContract" />
        <shib-staking v-if="smartContractType == 'BEP20'" :staking-contract="filteredStakingContracts[0]" />
        <div v-if="smartContractType != 'BEP20'">
          <h2 class="px-8 py-3 mt-24 text-3xl text-center text-white bg-red-600 rounded-xl">Staking is only available on BSC.</h2> 
        </div>
      </div>
      <div class="flex items-center justify-center">
        <a
          href="https://oklg.io/"
          target="_blank"
          class="my-20 text-base font-semibold text-center text-white cursor-pointer"
        >
          Powered by OKLG
        </a>
      </div>
    </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import KingStaking from "./KingStaking.vue"
import NftStaking from "./NftStaking.vue"
import ShibStaking from "./ShibStaking.vue"
import dayjs from "dayjs";
import BigNumber from "bignumber.js";

export default {
  name: "MainStaking",
  components: {
    KingStaking,
    NftStaking,
    ShibStaking
  },
  props: {
    filteredStakingContracts: {
      type: Array,
      default: []
    }
  },
  computed: {
    ...mapGetters([
      "initLoading",
      "activeNetwork",
      "web3",
      "selectedTokenAddress",
      "tokenStakingContracts",
      "currentBlock"
    ]),
    smartContractType() {
      if (this.activeNetwork.network_id == 1) {
        return "ERC20";
      } else if (this.activeNetwork.network_id == 56) {
        return "BEP20";
      } else {
        return "ERC20";
      }
    },
    kingShibContract() {
      return this.filteredStakingContracts.filter(cc => {
        return cc.currentTokenSymbol == "KINGSHIB" && cc.tokenAddy == "0x84F4f7cdb4574C9556A494DaB18ffc1D1D22316C" && cc.rewardAddy == "0x84F4f7cdb4574C9556A494DaB18ffc1D1D22316C"
      })[0]
    },
    kingNftContract() {
      return this.filteredStakingContracts.filter(cc => {
        return cc.currentTokenSymbol == "KINGNFT" && cc.tokenAddy == "0xe40a88012852Dc814e29bee5CF2Ed82d014e597f" && cc.rewardAddy == "0x84F4f7cdb4574C9556A494DaB18ffc1D1D22316C"
      })[0]
    }
  },
  data() {
    return {
      kingStakeModal: false,
      shibStakeModal: false
    }
  }
}
</script>