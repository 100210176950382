import { createStore } from 'vuex'

import kingshiba from './modules/kingshiba'

const store = createStore({
    modules: {
        kingshiba
    }
})

export default store