import { createWebHistory, createRouter } from "vue-router";
import Bridge from '../Bridge.vue'
import Staking from '../Staking.vue'
const history = createWebHistory();
const router = createRouter({
    history,
    routes: [
        {
            path: '/',
            component: Bridge
        },
        {
            path: '/staking',
            component: Staking
        }
    ]
})

export default router;