<template>
  <div
    class="fixed inset-0 z-10 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform shadow-xl rounded-2xl bg-king-modal sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6"
      >
        <div class="flex justify-end item-center">
          <button
            type="button"
            :disabled="isLoading"
            @click="$emit('close-modal')"
            class="text-white bg-transparent rounded-md hover:text-gray-300"
            :class="{
              'cursor-not-allowed bg-king-dark': isLoading
            }"
          >
            <span class="sr-only">Close</span>
            <!-- Heroicon name: outline/x -->
            <svg
              class="w-6 h-6 text-blue-700"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div v-if="isLoadingLocal" class="flex items-center justify-center flex-col p-10">
          <img class="h-32 w-32" src="../assets/logo.png" alt="">
          <h1 class="text-king-header text-2xl font-bold text-center mt-5">FETCHING DATA...</h1>
        </div>
        <div v-else class="flex flex-col">
          <div>
              <h1 class="text-2xl font-bold text-king-header">Stake Your Tokens (SHIB)</h1>
          </div>
          <div class="mt-4">
            <p class="text-sm text-white">
              Enter the amount you want to stake
            </p>
            <div
              class="flex flex-col items-center h-auto px-6 py-2 pb-4 mt-4 sm:flex-row sm:pb-2 rounded-xl bg-king-amount"
            >
              <div class="flex flex-col w-full pt-2 sm:pt-0">
                <input
                  v-model="swapAmount"
                  class="w-3/4 text-xl font-bold text-white bg-transparent border-none focus:outline-none focus:ring-2 focus:ring-offset focus:ring-gray-600"
                  type="text"
                />
                <p class="text-white text-opacity-50">
                  $KINGSHIB Balance:
                  <span>{{ userStakingBalance }}</span>
                </p>
              </div>
              <hr class="w-full my-4 border-gray-500 sm:hidden" />
              <div class="relative flex w-full sm:w-auto">
                <p class="text-2xl font-bold text-white text-opacity-30">
                  $KINGSHIB
                </p>
                <div
                  class="hidden mx-4 border-r border-gray-500 sm:block"
                ></div>
                <button
                  class="ml-auto font-semibold text-white hover:text-gray-300"
                  @click="maxAmount"
                >
                  Max
                </button>
              </div>
            </div>
          </div>
          <div class="flex flex-col py-5 mt-8">
            <p class="mb-4 text-sm text-white">
              Or use the slider/percent box below to set the amount to swap
            </p>
            <div class="px-5">
              <Slider
                v-model="swapPercentage"
                :tooltips="false"
                class="king-slider"
                ref="kingSlider"
                @update="onChangeSlider"
              />
            </div>
          </div>
          <div class="flex">
            <button
              type="button"
              class="inline-flex items-center justify-center w-full px-4 py-2 mt-5 mr-4 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-king-btn hover:bg-king-dark"
              :class="{
                'cursor-not-allowed bg-king-dark': isLoading
              }"
              :disabled="isLoading"
              @click="stakeTokens"
            >
              Stake
            </button>
            <button
              type="button"
              class="inline-flex items-center justify-center w-1/4 px-4 py-2 mt-5 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-king-btn hover:bg-king-dark"
              :class="{
                'cursor-not-allowed bg-king-dark': !isPastTimelock
              }"
              :disabled="!isPastTimelock"
              @click="unstakeTokens"
            >
              Unstake
            </button>
          </div>
          <template v-if="hasStakedTokens">
            <div v-if="!isPastTimelock">
              <p class="mt-5 font-semibold text-justify text-red-700">
                This farm has a {{ timelockDays }} day timelock. You originally staked at {{ timeUserOriginallyStaked }} and will be able to unstake your tokens after {{ timeUserCanUnstake }}.
              </p>
            </div>
          </template>
          <a
            href="https://oklg.io/"
            target="_blank"
            class="mt-8 text-base font-semibold text-center cursor-pointer text-king-header"
          >
            Powered by OKLG
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import BigNumber from "bignumber.js";
import Slider from "@vueform/slider";
import dayjs from "dayjs";
export default {
  components: {
    Slider
  },
  props: ["stakingContract", "isExpired"],
  data() {
    return {
        isLoadingLocal: true,
        isLoading: false,
        swapAmount: 0,
        swapPercentage: 0,
        stakingInfo: {},
        selectedNftTokenIds: []
    };
  },
  computed: {
    filteredStakingContracts() {
      return this.stakingContract
    },
    farmingTokenAddress() {
      return this.stakingContract.farmingTokenAddy;
    },
    tokenDecimals() {
      return this.stakingContract.currentTokenDecimals
    },
    canStake() {
      return this.stakingInfo.poolInfo.isStakedNft
        ? this.selectedNftTokenIds.length > 0
        : this.swapAmount > 0;
    },
    userStakingBalance() {
      return this.stakingInfo.poolInfo.isStakedNft
        ? this.stakingInfo.stakingTokenInfo.userBalance
        : new BigNumber(this.stakingInfo.stakingTokenInfo.userBalance)
            .div(
              new BigNumber(10).pow(this.stakingInfo.stakingTokenInfo.decimals)
            )
            .toFormat(0, BigNumber.ROUND_DOWN);
    },
    isPastTimelock() {
      if (!this.stakingInfo) return true;

      const timelockSeconds = this.stakingInfo.poolInfo.stakeTimeLockSec;
      if (!timelockSeconds) return true;

      const userStakedTime =
        this.stakingInfo.stakerInfo &&
        this.stakingInfo.stakerInfo.timeOriginallyStaked;
      if (!userStakedTime) return true;

      const isPastTime = dayjs(
        new BigNumber(userStakedTime).times(1e3).toNumber()
      )
        .add(timelockSeconds, "seconds")
        .isBefore(dayjs());
      if (isPastTime) return true;

      if (this.isExpired) return true;

      return false;
    },

    contractIsRemoved() {
      return this.stakingInfo.contractIsRemoved;
    },

    timelockDays() {
      const timelockSeconds = this.stakingInfo.poolInfo.stakeTimeLockSec;
      if (!timelockSeconds) return 0;
      return new BigNumber(timelockSeconds).div(60).div(60).div(24).toFormat();
    },

    hasStakedTokens() {
      return new BigNumber(this.stakingInfo.userStakingAmount).gt(0);
    },

    timeUserOriginallyStaked() {
      const userStakedTime = this.stakingInfo.stakerInfo.timeOriginallyStaked;
      return dayjs(new BigNumber(userStakedTime).times(1e3).toNumber()).format(
        "MMMM DD, YYYY HH:mm:ss"
      );
    },

    timeUserCanUnstake() {
      const timelockSeconds = this.stakingInfo.poolInfo.stakeTimeLockSec;
      const userStakedTime = this.stakingInfo.stakerInfo.timeOriginallyStaked;
      return dayjs(new BigNumber(userStakedTime).times(1e3).toNumber())
        .add(timelockSeconds, "seconds")
        .format("MMMM DD, YYYY HH:mm:ss");
    },
  },
  watch: {
    swapPercentage(value) {
      let currentBalance = this.stakingContract.currentTokenBalance / Math.pow(10, this.tokenDecimals)
      this.swapAmount = ((value * currentBalance) / 100).toFixed(2)
    },
    swapAmount(value) {
      this.swapPercentage = Math.round(100*value/(this.stakingContract.currentTokenBalance / Math.pow(10, this.tokenDecimals)))
    }
  },
  async mounted() {
    try {
      this.stakingInfo = await this.getFaasStakingInfo(this.stakingContract.farmingTokenAddy)
    } catch(err) {
      console.log(err)
    } finally {
      this.isLoadingLocal = false;
    }
  },
  methods: {
    ...mapActions([
      "init",
      "getFaasStakingInfo",
      "faasStakeTokens",
      "faasUnstakeTokens"
    ]),
    onChangeSlider(value) {
      document.getElementsByClassName(
        "slider-touch-area"
      )[0].innerText = `${value}%`;
    },
    maxAmount() {
      this.swapAmount = new BigNumber(this.stakingContract.currentTokenBalance)
        .div(new BigNumber(10).pow(this.tokenDecimals))
        .toFormat(2).replace(/,/g,'')
      this.swapPercentage = 100
    },
    async stakeTokens() {
      try {
        if (!this.canStake) return;
        this.isLoading = true
        const amountToFormat = new BigNumber(this.swapAmount.replace(/,/g, ""));
        const correctSendTokenAmount = new BigNumber(amountToFormat)
          .times(new BigNumber(10).pow(this.tokenDecimals))
          .toFixed()
        await this.faasStakeTokens({
          farmingContractAddress: this.farmingTokenAddress,
          stakingContractAddress: this.stakingInfo.stakingTokenInfo.address,
          amountTokens: correctSendTokenAmount,
          nftTokenIds: this.selectedNftTokenIds,
        })
        this.$notify({
          type: "success",
          text: "Successfully staked your tokens!",
        });
      } catch (err) {
        this.$notify({
          type: "error",
          text: err.message,
        });
      } finally {
        this.isLoading = false
        await this.init(true)
      }
    },
    async unstakeTokens() {
      try {
          this.isLoading = true
          await this.faasUnstakeTokens({
            farmingContractAddress: this.farmingTokenAddress,
          })
        this.$notify({
          type: "success",
          text: "Successfully unstaked all tokens!",
        });
      } catch (err) {
        console.error("Error unstaking tokens", err);
        this.$notify({
          type: "error",
          text: err.message,
        });
      } finally {
        this.isLoading = false
        await this.init(true)
      }
    }
  },
};
</script>